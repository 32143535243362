// About.jsx
import React from 'react';
import { motion, useInView, useScroll } from 'framer-motion';
import './CSS/About.css';
import Brain from '../components/Brain/Brain';
import Services from '../components/Services/Services';
import { useRef } from 'react';
import skillsDescription from '../constants/skillsDescriptionItems';

const About = () => {
  const scrollSvg = (
    <motion.svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={55}
      height={55}
    >
      <path
        d="M5 15C5 16.8565 5.73754 18.6371 7.05029 19.9498C8.36305 21.2626 10.1435 21.9999 12 21.9999C13.8565 21.9999 15.637 21.2626 16.9498 19.9498C18.2625 18.6371 19 16.8565 19 15V9C19 7.14348 18.2625 5.36305 16.9498 4.05029C15.637 2.73754 13.8565 2 12 2C10.1435 2 8.36305 2.73754 7.05029 4.05029C5.73754 5.36305 5 7.14348 5 9V15Z"
        stroke="#000000"
        strokeWidth="1"
      ></path>
      <path d="M12 6V14" stroke="#000000" strokeWidth="1"></path>
      <path d="M15 11L12 14L9 11" stroke="#000000" strokeWidth="1"></path>
    </motion.svg>
  );
  const containerRef = useRef();
  const { scrollYProgress } = useScroll({ container: containerRef });
  const skillRef = useRef();
  // const isSkillRefInView = useInView(skillRef, {once: true});
  const isSkillRefInView = useInView(skillRef, { margin: '-100px' });
  const bioRef = useRef();
  const isBioRefInView = useInView(bioRef, { margin: '-100px' });

  return (
    // CONTAINER
    <div className="app__bg section__padding">
      <div className="scrollable-container" ref={containerRef}>
        {/* TEXT CONTAINER */}
        <div className="text_container">
          {/* BIOGRAPHY CONTAINER */}
          <div className="biography_container" ref={bioRef}>
            <motion.h1
              initial={{ x: '-300px' }}
              animate={isBioRefInView ? { x: 0 } : {}}
              transition={{ delay: 0.2 }}
            >
              À PROPOS DE GO DEV
            </motion.h1>
            <motion.p
              className="p__custom-about"
              initial={{ opacity: 0, x: '-700px' }}
              animate={isBioRefInView ? { opacity: 1, x: 0 } : {}}
              transition={{ delay: 0.4 }}
            >
              Je me nomme Guichard Olivier, développeur web full-stack
              indépendant. Titulaire d’un diplôme en développement web, j’ai
              continué à perfectionner mes compétences pour proposer des
              solutions toujours plus performantes et adaptées aux besoins de
              mes clients.
              <br />
              <br /> À travers Go Dev, j’accompagne toutes les personnes ayant
              besoin d’un site web, qu’il s’agisse d’entreprises,
              d’entrepreneurs, de restaurants ou de tout autre type de projet.
              Animé par le désir de créer des solutions robustes et efficaces,
              j’apporte mon expertise pour concevoir des interfaces intuitives,
              élégantes et performantes. Que ce soit pour le front-end ou le
              back-end, mon ambition est de fournir un accompagnement
              personnalisé en intégrant les technologies les plus adaptées à vos
              besoins.
              <br />
              <br />
              Go Dev est là pour vous assister dans la création de votre site
              web et vous aider à donner vie à vos idées digitales.
            </motion.p>
          </div>
          {/* SCROLL SVG */}
          <div className="svg-scroll_container-bio">
            <motion.svg
              initial={{ opacity: 0.2, y: 0 }}
              animate={{ opacity: 1, y: '10px' }}
              transition={{
                repeat: Infinity,
                repeatType: 'mirror',
                duration: 3,
                ease: 'easeInOut',
              }}
            >
              {scrollSvg}
            </motion.svg>
          </div>
          {/* SKILLS CONTAINER */}
          <div className="skills_container" ref={skillRef}>
            <motion.h2
              initial={{ x: '-300px' }}
              animate={isSkillRefInView ? { x: 0 } : {}}
              transition={{ delay: 0.2 }}
            >
              COMPÉTENCES
            </motion.h2>
            <motion.div
              initial={{ x: '-700px' }}
              animate={isSkillRefInView ? { x: -18 } : {}}
              transition={{ delay: 0.2 }}
              className="skills_description"
            >
              {skillsDescription.map((skills) => (
                <div className="skills" key={skills.id}>
                  <p>{skills.label}</p>
                </div>
              ))}
            </motion.div>
          </div>
          {/* SCROLL SVG */}
          <div className="svg-scroll_container-skill">
            <motion.svg
              initial={{ opacity: 0.2, y: 0 }}
              animate={{ opacity: 1, y: '10px' }}
              transition={{
                repeat: Infinity,
                repeatType: 'mirror',
                duration: 3,
                ease: 'easeInOut',
              }}
            >
              {scrollSvg}
            </motion.svg>
          </div>
          {/* SERVICES CONTAINER */}
          <Services />
        </div>
        {/* SVG CONTAINER */}
        <div className="container_brain">
          <Brain scrollYProgress={scrollYProgress} />
        </div>
        <div className="container_empty">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>{' '}
    </div>
  );
};

export default About;
