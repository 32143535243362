// skillsDescriptionItems.js
const skillsDescription = [
  {
    id: 'html',
    label: 'Html',
  },
  {
    id: 'css',
    label: 'CSS',
  },
  {
    id: 'javascript',
    label: 'JavaScript',
  },
  {
    id: 'php',

    label: 'PHP',
  },

  {
    id: 'mysql',

    label: 'MySql',
  },
  {
    id: 'mongodb',

    label: 'MongoDb',
  },
  {
    id: 'react.js',

    label: 'React.js',
  },
  {
    id: 'node.js',

    label: 'Node.js',
  },
  {
    id: 'wordpress',

    label: 'WordPress',
  },
  {
    id: 'figma',

    label: 'Figma',
  },
  {
    id: 'git',

    label: 'Git',
  },
  {
    id: 'seo',

    label: 'SEO',
  },
];

export default skillsDescription;
