// PageContentContext.jsx
import React, { createContext, useState, useContext } from 'react';

const PageContentContext = createContext();

export const usePageContent = () => useContext(PageContentContext);

export const PageContentProvider = ({ children }) => {
  const [selectedNavItem, setSelectedNavItem] = useState(null);
  const [pageContent, setPageContent] = useState(null);
  const [isPageVisible, setPageVisible] = useState(false);

  return (
    <PageContentContext.Provider
      value={{
        pageContent,
        setPageContent,
        isPageVisible,
        setPageVisible,
        selectedNavItem,
        setSelectedNavItem,
      }}
    >
      {children}
    </PageContentContext.Provider>
  );
};
