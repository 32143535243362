// AnimateProject.jsx
import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { Observer } from 'gsap/Observer';
import axios from 'axios';
import './AnimateProject.css';

gsap.registerPlugin(Observer);

const AnimateProject = () => {
  const [projects, setProjects] = useState([]); // State to store projects retrieved from the API

  useEffect(() => {
    // Function to retrieve projects from the API
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          'https://god-dev-backend.onrender.com/api/project/getallprojects'
        ); // Change URL based on backend
        setProjects(response.data); // Update state with retrieved data
      } catch (error) {
        console.error('Erreur lors de la récupération des projets:', error);
      }
    };

    fetchProjects(); // Call the function to retrieve the projects when the component loads
  }, []);

  const sectionsRef = useRef([]);
  const imagesRef = useRef([]);
  const headingsRef = useRef([]);
  const outerWrappersRef = useRef([]);
  const innerWrappersRef = useRef([]);
  const animatingRef = useRef(false);
  const currentIndexRef = useRef(-1);
  const wrapRef = useRef();

  useEffect(() => {
    const sections = sectionsRef.current;
    const images = imagesRef.current;
    const headings = headingsRef.current;
    const outerWrappers = outerWrappersRef.current;
    const innerWrappers = innerWrappersRef.current;

    wrapRef.current = gsap.utils.wrap(0, sections.length);

    gsap.set(outerWrappers, { yPercent: 100 });
    gsap.set(innerWrappers, { yPercent: -100 });

    const gotoSection = (index, direction) => {
      index = wrapRef.current(index);
      animatingRef.current = true;
      const fromTop = direction === -1;
      const dFactor = fromTop ? -1 : 1;
      const tl = gsap.timeline({
        defaults: { duration: 1.25, ease: 'power1.inOut' },
        onComplete: () => (animatingRef.current = false),
      });

      if (currentIndexRef.current >= 0) {
        gsap.set(sections[currentIndexRef.current], { zIndex: 0 });
        tl.to(images[currentIndexRef.current], { yPercent: -15 * dFactor }).set(
          sections[currentIndexRef.current],
          { autoAlpha: 0 }
        );
      }

      gsap.set(sections[index], { autoAlpha: 1, zIndex: 1 });
      tl.fromTo(
        [outerWrappers[index], innerWrappers[index]],
        { yPercent: (i) => (i ? -100 * dFactor : 100 * dFactor) },
        { yPercent: 0 },
        0
      )
        .fromTo(images[index], { yPercent: 15 * dFactor }, { yPercent: 0 }, 0)
        .fromTo(
          headings[index].children,
          { autoAlpha: 0, yPercent: 150 * dFactor },
          {
            autoAlpha: 1,
            yPercent: 0,
            duration: 1,
            ease: 'power2',
            stagger: {
              each: 0.1,
              from: 'random',
            },
          },
          0.2
        );

      currentIndexRef.current = index;
    };

    Observer.create({
      type: 'touch,wheel,pointer',
      wheelSpeed: -1,
      onDown: () =>
        !animatingRef.current && gotoSection(currentIndexRef.current - 1, -1),
      onUp: () =>
        !animatingRef.current && gotoSection(currentIndexRef.current + 1, 1),
      tolerance: 10,
      preventDefault: true,
    });

    // Show the first section initially
    // gsap.set(sections[0], { autoAlpha: 1, zIndex: 1 });
    // gotoSection(0, 1);

    return () => {
      Observer.getAll().forEach((observer) => observer.kill());
    };
  }, [projects]);

  const items = projects;
  return (
    <div className="project_flex">
      {items.map((item, index) => (
        <section
          ref={(el) => (sectionsRef.current[index] = el)}
          key={item._id}
          className={`project_item ${item.color}`}
        >
          <div
            className="outer"
            ref={(el) => (outerWrappersRef.current[index] = el)}
          >
            <div
              className="inner"
              ref={(el) => (innerWrappersRef.current[index] = el)}
            >
              <div
                className="bg_container"
                style={{ backgroundImage: `url(${item.image})` }}
                ref={(el) => (imagesRef.current[index] = el)}
              >
                {/* <div className="overlay"></div> */}
                <h2
                  className="section-heading"
                  ref={(el) => (headingsRef.current[index] = el)}
                >
                  {item.title.split('').map((char, i) => (
                    <span key={i}>{char}</span>
                  ))}
                </h2>
                <p>{item.description}</p>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  Voir
                </a>
              </div>
            </div>
          </div>
        </section>
      ))}
    </div>
  );
};

export default AnimateProject;
