// Navbar.jsx
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { usePageContent } from '../../Context/PageContentContext';
import images from '../../constants/images';
import { navigationItems } from '../../constants/navigationItems';
import './Navbar.css';
import { FaLinkedin, FaGithub, FaTwitter } from 'react-icons/fa';
import { RxCross1 } from 'react-icons/rx';

const Navbar = () => {
  const [clickedDivId, setClickedDivId] = useState(null);
  const { setPageContent, setPageVisible } = usePageContent();
  const [allDivsOpen, setAllDivsOpen] = useState(false);
  const { setSelectedNavItem } = usePageContent();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1380) {
        setClickedDivId(null);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const currentNavItem = navigationItems.find(
      (item) => item.to === location.pathname
    );
    if (currentNavItem) {
      if (window.innerWidth >= 1380) {
        setClickedDivId(currentNavItem.id);
      }
      setPageContent(currentNavItem.content);
      setPageVisible(true);
    }
  }, [location, setPageContent, setPageVisible]);

  const handleDivClick = (id, content, label) => {
    if (window.innerWidth >= 1380 || (id !== 'project' && id !== 'contact')) {
      setClickedDivId(id);
    }
    setPageContent(content);
    setPageVisible(true);
    setSelectedNavItem(label);
  };

  const isDivLeftOpen = (id) => {
    return allDivsOpen || clickedDivId === id;
  };

  const handleRightElementClick = (id) => {
    setAllDivsOpen(true);
    setClickedDivId(id);
  };

  const categoryChoiceMobile = (id, content, label) => {
    setPageContent(content);
    setClickedDivId(id);
    setPageVisible(true);
    setSelectedNavItem(label);
  };

  return (
    <nav className="app__navbar">
      {/* NAVBAR DESKTOP */}
      <Link
        to="/"
        onClick={() => {
          setClickedDivId(null);
          setPageVisible(false);
        }}
      >
        <div className="app__navbar-logo">
          <img src={images.godev} alt="app logo" />
        </div>
      </Link>
      <div className="app__navbar-links">
        {navigationItems.map((item) => (
          <div key={item.id}>
            <Link to={item.to} onClick={(e) => e.preventDefault()}>
              <div
                className={`p__opensans-custom-navbar div-top ${
                  clickedDivId === item.id ? 'active' : ''
                }`}
                onClick={() =>
                  handleDivClick(item.id, item.content, item.label)
                }
              >
                <p
                  className={`p-label ${
                    clickedDivId === item.id ? 'p-down' : 'p-up'
                  }`}
                >
                  {item.label}
                </p>
              </div>

              <div
                className={`elevator ${
                  clickedDivId === item.id ? 'elevator-down' : 'elevator-up'
                }`}
              ></div>

              <div
                className={`p__opensans-custom div-bottom ${
                  clickedDivId === item.id
                    ? 'div-bottom-down active'
                    : 'div-bottom-up'
                }`}
                onClick={() =>
                  handleDivClick(item.id, item.content, item.label)
                }
              ></div>
            </Link>
          </div>
        ))}
      </div>
      {/* SOCIAL SECTION */}
      <div className="app__navbar-socials">
        <a
          href="http://linkedin.com/in/olivier-guichard-02aa1b272"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin />
        </a>
        {/* <a
          href="https://github.com/yourprofile"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaGithub />
        </a>
        <a
          href="https://twitter.com/yourprofile"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter />
        </a> */}
      </div>
      {/* NAVBAR MOBILE */}
      <div className="app__navbar-smallscreen">
        <div
          className="app__navbar-smallscreen_links"
          onClick={handleRightElementClick}
        >
          {navigationItems.map((item, index) => (
            <div key={item.id}>
              <Link
                to={item.to}
                onClick={(e) => {
                  if (!allDivsOpen) {
                    e.preventDefault();
                  } else {
                    categoryChoiceMobile(item.id, item.content, item.label);
                  }
                }}
              >
                <div className="p__opensans-custom div-right-smallscreen">
                  <div
                    className={`elevator-lateral ${
                      isDivLeftOpen(item.id) ? 'active' : 'inactive'
                    } ${
                      index === 0
                        ? 'elevator-lateral-animation1'
                        : index === 1
                        ? 'elevator-lateral-animation2'
                        : index === 2
                        ? 'elevator-lateral-animation3'
                        : 'elevator-lateral-animation4'
                    }`}
                  >
                    <p className="p-label-smallscreen">{item.label}</p>
                  </div>
                </div>

                <div
                  className={`p__opensans-custom div-left-smallscreen ${
                    isDivLeftOpen(item.id)
                      ? 'div-left-left active'
                      : 'div-left-right '
                  }`}
                ></div>
              </Link>
            </div>
          ))}
        </div>

        <div>
          {allDivsOpen && (
            <RxCross1
              className="rx-cross1-animation"
              color="black"
              onClick={() => setAllDivsOpen(false)}
            />
          )}
        </div>
        {/* SOCIAL SECTION MOBILE */}
        <div className="app__navbar-smallscreen_socials">
          <a
            href="http://linkedin.com/in/olivier-guichard-02aa1b272"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
          {/* <a
            href="https://github.com/yourprofile"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaGithub />
          </a>
          <a
            href="https://twitter.com/yourprofile"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter />
          </a> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
