// Contact.jsx
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import './CSS/Contact.css';
import images from '../constants/images';
import axios from 'axios';
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import swal from 'sweetalert';

const Contact = () => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const text = 'Contact';

  const formik = useFormik({
    initialValues: {
      user_name: '',
      user_email: '',
      user_phone: '',
      user_message: '',
      acceptedTerms: false,
    },
    validationSchema: Yup.object({
      user_name: Yup.string()
        .matches(
          /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/,
          'Nom/Prénom ne peut contenir que des lettres'
        )
        .required('Nom/Prénom est requis'),
      user_email: Yup.string()
        .email('Adresse email invalide')
        .required('Adresse email est requise'),
      user_phone: Yup.string()
        .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, 'Numéro de téléphone invalide')
        .optional(),
      user_message: Yup.string().required('Message est requis'),
      acceptedTerms: Yup.boolean()
        .required('Vous devez accepter les termes.')
        .oneOf([true], 'Vous devez accepter les termes.'),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSuccess(false);
      setError(false);
      try {
        await axios.post(
          'https://god-dev-backend.onrender.com/send-email',
          values
        );
        setSuccess(true);
        swal('Succès', 'Votre message a été envoyé avec succès !', 'success'); // Show success alert
        resetForm();
      } catch (error) {
        setError(true);
        swal('Erreur', "Quelque chose s'est mal passé !", 'error'); // Show error alert
      }
      setSubmitting(false);
    },
  });

  return (
    <div className="contact_container">
      <div className="title-contact_container">
        <h1>
          {text.split('').map((letter, index) => (
            <motion.span
              key={index}
              initial={{ opacity: 1 }}
              animate={{ opacity: 0 }}
              transition={{ duration: 3, repeat: Infinity, delay: index * 0.1 }}
            >
              {letter}
            </motion.span>
          ))}{' '}
          <img src={images.godev} alt="app logo" />
        </h1>

        <div className="contact_explanation">
          <p>
            Je suis à votre disposition pour discuter de vos projets, répondre à
            vos questions ou établir un devis personnalisé. Que ce soit pour un
            développement web, ou toute autre demande spécifique, n'hésitez pas
            à me contacter. Je m'efforce de répondre dans les plus brefs délais
            pour vous accompagner dans la réalisation de vos idées.
          </p>
        </div>
        <ul className="contact_details">
          <li className="contact_detail-item">
            <div className="icon_circle">
              <FaPhoneAlt />
            </div>
            <a href="tel:+33624269795">(+33)6.24.26.97.95</a>
          </li>
          <li className="contact_detail-item">
            <div className="icon_circle">
              <FaEnvelope />
            </div>
            <a href="mailto:o.guichard@godeveloper.fr">
              o.guichard@godeveloper.fr
            </a>
          </li>
        </ul>
      </div>
      <form onSubmit={formik.handleSubmit} className="form_container">
        <span>*Nom/Prénom:</span>
        <input
          type="text"
          className={`name_container input_container ${
            formik.touched.user_name && formik.errors.user_name
              ? 'input_error'
              : ''
          }`}
          name="user_name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.user_name}
        />
        {formik.touched.user_name && formik.errors.user_name && (
          <div className="contact_text-error">{formik.errors.user_name}</div>
        )}

        <span>*E-Mail:</span>
        <input
          type="email"
          className={`input_container ${
            formik.touched.user_email && formik.errors.user_email
              ? 'input_error'
              : ''
          }`}
          name="user_email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.user_email}
        />
        {formik.touched.user_email && formik.errors.user_email && (
          <div className="contact_text-error">{formik.errors.user_email}</div>
        )}

        <span>Téléphone:</span>
        <input
          type="text"
          className={`input_container ${
            formik.touched.user_phone && formik.errors.user_phone
              ? 'input_error'
              : ''
          }`}
          name="user_phone"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.user_phone}
        />
        {formik.touched.user_phone && formik.errors.user_phone && (
          <div className="contact_text-error">{formik.errors.user_phone}</div>
        )}

        <span>*Votre message:</span>
        <textarea
          rows={5}
          className={`contact_text ${
            formik.touched.user_message && formik.errors.user_message
              ? 'input_error'
              : ''
          }`}
          name="user_message"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.user_message}
        ></textarea>
        {formik.touched.user_message && formik.errors.user_message && (
          <div className="contact_text-error">{formik.errors.user_message}</div>
        )}

        <div className="checkbox_container">
          <input
            type="checkbox"
            name="acceptedTerms"
            id="acceptedTerms"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            checked={formik.values.acceptedTerms}
          />
          <label htmlFor="acceptedTerms">
            En soumettant ce formulaire, j'accepte que les informations saisies
            soient exploitées dans le cadre de la demande de contact et de la
            relation commerciale qui peut en découler.
          </label>
        </div>
        {formik.touched.acceptedTerms && formik.errors.acceptedTerms && (
          <div className="contact_text-error">
            {formik.errors.acceptedTerms}
          </div>
        )}

        <button
          type="submit"
          className="custom__button-contact slide-bottom"
          disabled={formik.isSubmitting || !formik.values.acceptedTerms}
        >
          Envoyer
        </button>
      </form>
      <ul className="contact_details-mobile">
        <li className="contact_detail-item">
          <div className="icon_circle">
            <FaPhoneAlt />
          </div>
          <a href="tel:+33624269795">(+33)6.24.26.97.95</a>
        </li>
        <li className="contact_detail-item">
          <div className="icon_circle">
            <FaEnvelope />
          </div>
          <a href="mailto:o.guichard@godeveloper.fr">
            o.guichard@godeveloper.fr
          </a>
        </li>
      </ul>{' '}
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default Contact;
