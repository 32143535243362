// Project.jsx
import React, { useEffect, useRef } from 'react';
import './CSS/Project.css';
import AnimateProject from '../components/AnimateProject/AnimateProject';
import { motion } from 'framer-motion';
import { gsap } from 'gsap';
import { PiMouseScrollFill } from 'react-icons/pi';
const Project = () => {
  const headingRef = useRef();

  useEffect(() => {
    const letters = headingRef.current.children;
    gsap.fromTo(
      letters,
      { autoAlpha: 0, yPercent: 150 },
      {
        autoAlpha: 1,
        yPercent: 0,
        duration: 1.5,
        ease: 'power2',
        stagger: {
          each: 0.1,
          from: 'random',
        },
      }
    );
  }, []);

  return (
    <div className="app__bg app__header app__wrapper-project section__padding">
      <div className="project_container">
        <h1 ref={headingRef}>
          {'Decouvrez mes projets'.split('').map((char, i) => (
            <span key={i}>{char}</span>
          ))}
        </h1>
        <div className="project_scroll">
          <motion.div
            initial={{ opacity: 0.2, y: 0 }}
            animate={{ opacity: 1, y: '10px' }}
            transition={{
              repeat: Infinity,
              repeatType: 'mirror',
              duration: 3,
              ease: 'easeInOut',
            }}
          >
            <PiMouseScrollFill />
          </motion.div>
        </div>
      </div>
      <AnimateProject />
    </div>
  );
};

export default Project;
