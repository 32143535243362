// Services.jsx
import React from 'react';
import Services from '../components/Services/Services';
import './CSS/Services.css';

const Skill = () => {
  return (
    <div className="app__wrapper-services">
      <Services />
    </div>
  );
};

export default Skill;
