// App.js
import React, { useEffect, useState } from 'react';
import Navbar from './components/Navbar/Navbar';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Services from './Pages/Services';
import Project from './Pages/Project';
import Contact from './Pages/Contact';
import './App.css';
import { PageContentProvider } from './Context/PageContentContext';
import PageOverlay from './components/ExtraContent/PageOverlay';
import LoadingPage from './Pages/LoadingPage';

const App = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Simulate a loading delay
    const timeout = setTimeout(() => {
      setLoaded(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <div className={`app-container ${loaded ? 'loaded' : ''}`}>
      {!loaded && <LoadingPage />}
      <PageContentProvider>
        <BrowserRouter>
          <Navbar />
          <PageOverlay />
          <MainContent />
        </BrowserRouter>
      </PageContentProvider>
    </div>
  );
};

const MainContent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleRefresh = () => {
      navigate('/');
    };

    window.addEventListener('load', handleRefresh);
    return () => window.removeEventListener('load', handleRefresh);
  }, [navigate]);

  return (
    <div className="main-content">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Services />} />
        <Route path="/project" element={<Project />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
};

export default App;
