// LoadingPage.jsx
import React from 'react';
import './CSS/LoadingPage.css';
import images from '../constants/images';
import { motion } from 'framer-motion';

const LoadingPage = () => {
  return (
    <div className="loading-page">
      <div className="loading-animation">
        <motion.img
          src={images.godev}
          alt="app logo"
          animate={{
            scale: [1, 1.1, 1],
            opacity: [0, 0.7, 0],
            // rotate: [0, 5, -5, 0],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            repeatType: 'loop',
            ease: 'easeInOut',
          }}
        />
      </div>
    </div>
  );
};

export default LoadingPage;
