// LiIcon.jsx
import React, { useRef } from 'react';
import './LiIcon.css';
import { motion, useInView } from 'framer-motion';

const LiIcon = ({ reference }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: 0.5 });

  return (
    <figure className="container__figure" ref={ref}>
      <svg className="svg-rotate" width="75" height="75" viewBox="0 0 100 100">
        <circle cx="75" cy="50" r="20" className="first-circle" />
        <motion.circle
          cx="75"
          cy="50"
          r="20"
          className="second-circle"
          initial={{ pathLength: 0 }}
          animate={isInView ? { pathLength: 1.5 } : { pathLength: 0 }}
          transition={{ duration: 3 }}
        />
        <circle cx="75" cy="50" r="10" className="third-circle" />
      </svg>
    </figure>
  );
};

export default LiIcon;
