// PageOverlay.jsx
import React, { useEffect, useState } from 'react';
import { usePageContent } from '../../Context/PageContentContext';
import './PageOverlay.css';

const PageOverlay = () => {
  const { pageContent, isPageVisible, selectedNavItem, setSelectedNavItem } =
    usePageContent();
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    const mainContent = document.querySelector('.main-content');
    if (mainContent) {
      if (isPageVisible) {
        mainContent.classList.add('overlay-active');
      } else {
        mainContent.classList.remove('overlay-active');
      }
    }
  }, [isPageVisible]);

  useEffect(() => {
    if (isPageVisible) {
      // Trigger re-render or re-animation when the overlay becomes visible
      requestAnimationFrame(() => {
        const animationElements = document.querySelectorAll('.animate-project');
        animationElements.forEach((element) => {
          element.style.animation = 'none';
          requestAnimationFrame(() => {
            element.style.animation = '';
          });
        });
      });
    }
  }, [isPageVisible]);

  useEffect(() => {
    if (initialLoad) {
      const storedNavItem = localStorage.getItem('selectedNavItem');
      if (storedNavItem) {
        setSelectedNavItem(storedNavItem);
      }
      setInitialLoad(false);
    } else if (selectedNavItem) {
      localStorage.setItem('selectedNavItem', selectedNavItem);
    }
  }, [selectedNavItem, setSelectedNavItem, initialLoad]);

  return (
    <div className={`page-overlay ${isPageVisible ? 'visible' : ''}`}>
      <div className="page-overlay-content">
        <div className="category">{selectedNavItem}</div>
        {pageContent}
      </div>
    </div>
  );
};

export default PageOverlay;
