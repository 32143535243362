// navigationItems.js
import About from '../Pages/About';
// import Services from '../Pages/Services';
import Projects from '../Pages/Project';
import Contact from '../Pages/Contact';

export const navigationItems = [
  {
    id: 'about',
    to: '/about',
    label: 'A propos',
    content: <About />,
  },
  // {
  //   id: 'service',
  //   to: '/service',
  //   label: 'Services',
  //   content: <Services />,
  // },
  {
    id: 'project',
    to: '/projects',
    label: 'Projets',
    content: <Projects />,
  },
  {
    id: 'contact',
    to: '/contact',
    label: 'Contact',
    content: <Contact />,
  },
];
