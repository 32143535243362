// Services.jsx
import React, { useRef } from 'react';
import './Services.css';
import { motion, useInView } from 'framer-motion';
import LiIcon from '../LiIcon/LiIcon';

const Services = () => {
  const Details = ({ services, work }) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { amount: 0.5 });

    return (
      <li ref={ref} className="services_details">
        <LiIcon reference={ref} />
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={isInView ? { y: 0, opacity: 1 } : { y: 50, opacity: 0 }}
          transition={{ duration: 0.5, type: 'spring' }}
        >
          <h4>{services}</h4>
          <p>{work}</p>
        </motion.div>
      </li>
    );
  };

  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });

  const servRef = useRef();
  const isServRefInView = useInView(servRef, { margin: '-50px' });

  return (
    <div className="services_container">
      <motion.h3
        ref={servRef}
        initial={{ x: '-300px' }}
        animate={isServRefInView ? { x: 0 } : {}}
        transition={{ delay: 0.2 }}
      >
        MES SERVICES
      </motion.h3>
      <div ref={ref} className="container_details">
        <motion.div
          className="detail_line"
          initial={{ scaleY: 0 }}
          animate={isInView ? { scaleY: 1 } : { scaleY: 0 }}
          transition={{ duration: 5, ease: 'easeInOut' }}
        />
        <ul>
          <Details
            services="CONCEPTION ET MAQUETTAGE"
            work="Création de maquettes visuelles et de prototypes pour donner vie à vos idées."
          />
          <Details
            services="DÉVELOPPEMENT FRONT END PERSONNALISÉ"
            work="Développement d'interfaces utilisateur sur mesure, utilisant les dernières technologies web pour des sites rapides et réactifs."
          />
          <Details
            services="DÉVELOPPEMENT BACK END"
            work="Construction de systèmes robustes et sécurisés côté serveur, intégrant des bases de données et des API pour des fonctionnalités avancées."
          />
          <Details
            services="DÉVELOPPEMENT WORDPRESS"
            work="Réalisation de sites WordPress personnalisés, de la conception à la mise en ligne, en utilisant des thèmes et des plugins adaptés à vos besoins."
          />
          <Details
            services="SEO (OPTIMISATION POUR LES MOTEURS DE RECHERCHE)"
            work="Optimisation de votre site pour les moteurs de recherche afin d'améliorer sa visibilité et son classement, attirant ainsi plus de visiteurs."
          />
          <Details
            services="CRÉATION DE SITES VITRINE"
            work="Développement de sites vitrines élégants et fonctionnels pour présenter votre entreprise ou vos projets de manière professionnelle."
          />
          <Details
            services="CRÉATION DE SITES E-COMMERCE"
            work="Conception et mise en place de boutiques en ligne performantes, avec une gestion simplifiée des produits et des commandes."
          />
          <Details
            services="SITES RESPONSIVES"
            work="Adaptation de votre site pour qu'il soit parfaitement compatible avec tous les appareils (ordinateurs, tablettes, smartphones) pour une expérience utilisateur fluide."
          />
          <Details
            services=" MAINTENANCE"
            work="Proposition de services de maintenance pour garder votre site à jour, sécurisé et performant."
          />
        </ul>
      </div>
    </div>
  );
};

export default Services;
